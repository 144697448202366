<template>
<div class="modal-content-pp">
    <ion-header class="headerTop">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal()">
            <ion-icon class="closebutton" src="assets/ICONOS/atras.svg"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding contentt"> 
      <table class="mtable"  :class="array[0].suc ? 'modalMusicList' : ''">
        <tr>
          <th class="fecha">Fecha</th>
          <th class="token">Audios</th>
          <th class="hora">Horas</th>
        </tr>
        <tr v-for="item in array" :key="item">
          <td>{{ item.date }}</td>
          <td>{{ item.count }}</td>
          <td>{{ formatSecondsToHours(item.seconds) }}</td>
        </tr>
      </table> 
    </ion-content>
</div>
  </template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';

export default defineComponent({
  name: 'sucHoraListModal',
  props: {
    title: { type: String, default: 'Super Modal' },
    array: { type: Array, default: () => [] },
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar },
  methods: {
        closeModal() {
            modalController.dismiss({dismissed: true});
        },
        formatSecondsToHours(seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            return `${hours}h ${minutes}m`;
        }
  },
  setup() {
      return {close,};
  },
});
</script>

<style scoped>
.closebutton{
    color: #FFF;
}
.contentt   { 
    margin: 0 auto; 
    padding: 10px;  
    color: #000;  
    height: calc(100% - 60px);
}
.modal-content-pp {
    min-height: calc(100% - 30px);
    border: solid 1px #ccc;
}
.mtable {
    width: 100%;
    border-collapse: collapse;
}
.mtable th {  
    font-weight: bold;
    position: relative;
}
.mtable td, .mtable th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}
.mtable tr:nth-child(even) {
    background-color: #111;
}
 
.mtable tr:hover {
    background-color: #333;
}
.mtable .token:hover:before {
    content: 'TOKEN: Orden de reproducción del día';
    position: absolute;
    background-color: #000;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    left: 0;
    
}
.modalMusicList tr td {
    padding: 2px;
    font-size: 12px;
}
</style>