<template>
    <ion-page>
      <ion-header class="headerTop ion-no-border">
        <headertoolbar></headertoolbar>
      </ion-header>
      <ion-content :fullscreen="true">
        <div class="original_main_container">
          <ion-header collapse="condense"></ion-header>
          <ion-icon class="l_close_a" button @click="this.$router.push('/admin/dashboard')"
            src="assets/ICONOS/close.svg"></ion-icon>
  
                <p class="tabTitle">Horas por Sucursal
                  <ion-icon class="config_icon_question" src="assets/ICONOS/questionmark.svg" 
                      @click="openInfoModal('Horas por Sucursal', 'Este reporte muestra la cantidad de tiempo que las sucursales reprodujeron contenido, dentro de un periodo de tiempo no mayor a un mes. Para mostrar información más precisa, este reporte se basa en las peticiones que hace cada sucursal al servidor… es decir, al finalizar una canción se realiza una segunda petición y eso suma el tiempo que estuvo al aire, si la sucursal esta al aire, pero el reproductor se encuentra pausado… este reporte no contará el tiempo en pausa.', 
  
  
  [
    {
      id: 0,
      mrg: 20,
      name: 'La tabla cuenta con una función de filtrado que facilita la búsqueda de contenidos específicos. También tiene un botón de \'Detalles\' que muestra una tabla emergente con la cantidad de audios reproducidos por día y las horas en línea:'
    },
    {
      id: 1,
      mrg: 10,
      name: ' 1. Audios (indica la cantidad de audios reproducidos en el periodo seleccionado)'
    },
    {
      id: 2,
      mrg: 10,
      name: ' 2. Titulo'
    },
    {
      id: 3,
      mrg: 10,
      name: ' 3. Horas (indica el tiempo al aire de la sucursal en horas y minutos)'
    },
    {
      id: 4,
      mrg: 20,
      name: '¡ALERTA! Este reporte se basa en análisis de logs, por lo que, si usted cuenta con muchas sucursales y estas reproducen mucho contenido, el reporte puede tardar en cargar. Si el reporte no carga, intente seleccionar un rango de fechas más corto.'
    }
    
  ])"
                      />
                    </p>
  
                  <select class="channel_select_e" ref="selectedChannel" v-model="selectedChannel" @change="handleChannelChange" aria-label="Canal" interface="popover" placeholder="Selecciona el canal">
                    <option v-for="item in channel_name_list" :key="item.id" :value="item.id">
                      {{ item.name }}
                    </option>
                  </select>
  
                  <ion-grid class="log_details_g">
                    <ion-row>
                      <ion-col class="mlog_date_div_es">
  
                          <ion-datetime v-model="date_from" class="spot_date_select spot_date_select_from"  :class="date_validation" @ionChange="handleDateChange" :min="minDate" :max="maxDate" display-format="DD/MM/YYYY" picker-format="DD/MM/YYYY" cancel-text="Cancelar" done-text="Aceptar"></ion-datetime>
                          
                          <ion-datetime v-model="date_to" class="spot_date_select spot_date_select_to"  :class="date_validation" @ionChange="handleDateChange" :min="minDate" :max="maxDate" display-format="DD/MM/YYYY" picker-format="DD/MM/YYYY" cancel-text="Cancelar" done-text="Aceptar"></ion-datetime>
  
                          <p class="alertaFechas" v-show="date_validation === 'menor'">
                            La fecha de inicio no puede ser mayor a la fecha final.
                          </p>
                          <p class="alertaFechas" v-show="date_validation === 'mayor'">
                            No se pueden consultar mas de 31 días en un solo reporte.
                          </p>
                          
                      </ion-col>
                      <ion-col class="spot_log_select_div">
                        <select class="spot_log_select_e" v-model="selectedlog" @change="handlelogChange" aria-label="Canal" interface="popover" placeholder="Selecciona el canal">
                            <!-- <option  key="Todos" value="Todos">
                                Todos
                            </option> -->
                            <option v-for="item in log_list" :key="item.id" :value="item[0]">
                                {{ item[1] }}
                            </option>
                      </select>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                  
                  <button class="cargar_reportes_button sc-btn-3" @click="cargarReportes()">
                    <span>
                      Cargar Reportes
                    </span>
                  </button>
                  
                  <ion-card v-if="(log_list.length<=0 && isloading == false) || alertaSinArvhivos " color="warning" class="connectionalert outline-card" ouline>
                    <ion-card-header>
                      <ion-card-title>Sin Registros.</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                      No existen registros para la fecha seleccionada.
                    </ion-card-content>
                  </ion-card>
  
                  
  
                  <div class="out_loader" v-if="isloading">
                    <div class="loader"></div>
                  </div>
                  
                  <div class="admin_report_filter">
                    <div class="m_count_l" v-show="mhora_s_table.length > 0 && !isloading">
                      Registros mostrados: {{ filteredLogs.length }} / {{ mhora_s_table.length }}
                    </div>

                    <div class="filter-input-con" v-show="mhora_s_table.length > 0 && !isloading">
                      <ion-input class="filterInput" placeholder="Filtrar por Titulo" @keyup="filterLogs" ref="textFilter"></ion-input>
                      <ion-icon class="filterIcon filtersearch" src="assets/ICONOS/search.svg"></ion-icon>
                      <ion-icon src="assets/ICONOS/close_f.svg" class="filterIcon filterclose" @click="this.$refs.textFilter.value = ''; filterLogs();"></ion-icon>
                      <div class="filter-excel-con">
                        <button class="exportButton" @click="exportToExcel">
                          <img src="img-admin/excel.png" >
                        </button>
                      </div>
                    </div>


                    <div class="m_count_r"  v-show="mhora_s_table.length > 0 && !isloading">
                      Registros mostrados: {{ filteredLogs.length }} / {{ mhora_s_table.length }}
                    </div>
                  </div>
  
                  <table v-show="mhora_s_table.length>0 && isloading == false" class="mhora_s_table">
                    <thead>
                      <tr>
                        <th class="count">Audios</th>
                        <th class="title">Título</th>
                        <th class="seconds">Horas</th>
                        <th colspan="1" class="acciones">Acciones</th> 
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in filteredLogs" :key="item.url">
                        <td>{{ item.count }}</td>
                        <td>{{ item.title }}</td>
                        <td>{{ formatSecondsToHours(item.seconds) }}</td>
                        <td class="accioness">
                          <a class="pp_detallesButton"
                            @click="openModal(item.title, item.logs)"
                          >
                            <ion-icon src="assets/ICONOS/detalles.svg"></ion-icon>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>  
                  
                  <div class="vMusicFloat" v-show="vMusicPlayerVisible">
                    <div class="vMusicDiv">
                      <a @click="vMusicPlayerClose" class="vMusicClose">
                        <ion-icon class="vMusicCloseIcon" src="assets/ICONOS/close_f.svg"></ion-icon>
                      </a>
                      <p>{{ vMusicPlayerName }}</p>
                      <audio id="vMusicPlayer" controls>
                        Your browser does not support the audio element.
                      </audio>
                    </div> 
                  </div>
                  <div class="bottom-space-on-float-audio" v-show="vMusicPlayerVisible"></div>
                  <br>
                  <br>
                  <br>
        </div>
      </ion-content>
      <!-- <ion-button @click="signOut" class="logoutButtonCabina"><ion-icon src="assets/ICONOS/cabina/log-out-outline.svg"></ion-icon>Cerrar sesión</ion-button> -->
    </ion-page>
  </template>
  
  
  <script>
  /* eslint-disable */
  import { alertController, IonPage, IonHeader, IonToolbar, IonContent, IonRefresher, IonRefresherContent, IonDatetime, modalController } from '@ionic/vue';
  import axios from 'axios';
  import { defineComponent } from 'vue';
  import { Storage } from '@capacitor/storage';
  import headertoolbar from '../header_toolbar.vue';
  import { Capacitor } from '@capacitor/core';
  import 'url-polyfill';
  import 'blob-polyfill'; 
  import { max, min } from 'moment-timezone';
  import moment from 'moment';
  import * as XLSX from 'xlsx';
  
  
  import sucHoraListModal from '../sucHoraListModal.vue';
  import infoModal from '../infoModal.vue';
  //import { toastController } from '@ionic/vue';
  
  export default ({
    name: 'Reproductor',
    components: { IonHeader, IonToolbar, IonContent, IonPage, headertoolbar, IonRefresher, IonRefresherContent, IonDatetime },
    data() {
      return {
        urlApi: 'https://panel.vybroo.net/',
        clientData: null,  
        token: null,
        serverDate: null,
        clientData: {}, 
        channel_name_list: [],
        channel_list: [],
        selectedChannel: null,
        
        log_list: [],
        selectedlog: null,
        isloading: true,
        dashboard: 
          {
            client: {
              email: "",
              id: 0,
              name: "",
              obligation: 0,
              retainer: 0
            },
            radio: {
              blacklist: "",
              branches: 0,
              categories: "",
              image: "2f07f7cfc8d4348937078a566c7d943e.png",
              link: "",
              mobileimage: "6b8b53effc9683373db10671d4bf0687.png",
              name: "",
              tags_blacklist: "",
              video_enabled: false
            },
            spots: {
              id: 0,
              level: 0,
              month: "",
              name: "",
              obligation: 0,
              percentage: "",
              produced: null,
              year: ""
            },
          },
          date_from: moment().startOf('month').format('YYYY-MM-DD'),
          date_to: moment().subtract(1, 'day').format('YYYY-MM-DD'),
          minDate: '2023-01-01',
          maxDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
          date_validation: 'ok',
          mhora_s_table: [],
          filteredLogs: [],
          logTitleObj: {},
          muteado: false,
          vMusicPlayerName: null,
          vMusicPlayerVisible: false,
          alertaSinArvhivos: false,
          reportName: '',
          reportFirstPage: '',
  
      }
    },
    methods: {  
      exportToExcel() {
      const workbook = XLSX.utils.book_new();

      // Crear la primera hoja con el concentrado
      const summaryData = this.getSummaryData();
      const summarySheet = XLSX.utils.json_to_sheet(summaryData);
      
        let sheetName1 = this.reportFirstPage;
        if (sheetName1.length > 30) {
          sheetName1 = sheetName1.replace(/[\\/:?*\[\]]/g, '').substring(0, 27) + '...';
        }

      XLSX.utils.book_append_sheet(workbook, summarySheet, sheetName1);

      // Crear una hoja de trabajo para cada sucursal
      this.mhora_s_table.forEach((item, index) => {
        const tableData = this.getTableData(item);
        const worksheet = XLSX.utils.json_to_sheet(tableData);
        let sheetName2 = item.title;
        if (sheetName2.length > 30) {
          sheetName2 = sheetName2.substring(0, 27) + '...';
        }
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName2);
      });

      // Generar el archivo Excel
      XLSX.writeFile(workbook, this.reportName + ".xlsx");
    },

    // Método para obtener los datos del concentrado
    getSummaryData() {
      const summaryData = [];
      this.mhora_s_table.forEach(item => {
        summaryData.push({
          Audios: item.count,
          Sucursal: item.title,
          Horas: this.formatSecondsToHours(item.seconds),
          Minutos_Totales: Math.round(item.seconds / 60),
        });
      });
      return summaryData;
    },

    // Método para obtener los datos de la tabla
    getTableData(item) {
      const tableData = [];
      item.logs.forEach(log => {
        tableData.push({
          Fecha: log.date,
          Audios: log.count,
          Horas: this.formatSecondsToHours(log.seconds),
          Minutos_Totales: Math.round(log.seconds / 60),
        });
      });
      return tableData;
    },
      async openInfoModal(title, desc, list) {
        const modal = await modalController.create({
          component: infoModal,
          componentProps: {
            title: title,
            desc: desc,
            list: list,
          },
        });
        return modal.present();
      },
      async openModal(title, array) {
        const modal = await modalController.create({
          component: sucHoraListModal,
          componentProps: {
            title: title,
            array: array,
          },
        });
        return modal.present();
      },
      formatSecondsToHours(seconds) {
          const hours = Math.floor(seconds / 3600);
          const minutes = Math.floor((seconds % 3600) / 60);
          return `${hours}h ${minutes}m`;
      },
      vMusicPlayerClose() {
        const escucharButton = document.querySelectorAll('.pp_escucharButton');
        escucharButton.forEach(element => {
          element.classList.remove('activo');
        });
        const audio = document.getElementById('vMusicPlayer');
        audio.pause();
        this.vMusicPlayerVisible = false;
      },
      async escucharAudio(url, name) {
        if (this.muteado == true) {
          this.reproducirAudio(url, name);
        }else{
            try {
              const alert = await alertController
                .create({
                  header: "Aviso",
                  message: "¿Deseas reproducir este audio? se pausará el reproductor principal.",
                  buttons: [
                    {
                      text: 'Si',
                      handler: () => {  
                        this.reproducirAudio(url, name);
                        this.muteado = true;
                      }
                    },
                    {
                      text: 'Cancelar',
                      handler: () => {
                        console.log('Cancelar pulsado');
                      }
                    },
                  ]
                });
              return alert.present();
            } catch (e) {
              this.presentAlertConfirm("Ha ocurrido un error::escucharAudio:", e);
            } 
        }
      },
      reproducirAudio (url, name) {
        const escucharButton = document.querySelectorAll('.pp_escucharButton');
        escucharButton.forEach(element => {
          element.classList.remove('activo');
        });
        const escucharButtonActivo = document.querySelector(`.pp_escucharButton[data-url="${url}"]`);
        escucharButtonActivo.classList.add('activo'); 
  
        this.vMusicPlayerVisible = true;
        this.emitter.emit("rep-func-pause", "Muteando Reproductor");
        const audio = document.getElementById('vMusicPlayer');
        audio.src = 'https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev' + url.substring(2);
        audio.play();
        this.vMusicPlayerName = name;
        audio.onended = () => {
          console.log('EL AUDIO HA TERMINADO');
          escucharButtonActivo.classList.remove('activo');
        };
      },
      filterLogs() {
        let filter = this.$refs.textFilter.value.toLowerCase();
        this.filteredLogs = [];
        this.filteredLogs = this.mhora_s_table.filter(log => {
          return log.title.toLowerCase().includes(filter);
        }); 
      },
      handleChannelChange(event) { 
        this.selectedChannel = event.target.value;
        this.emitter.emit("selectedChannel", event.target.value);
                Storage.set({
                  key: "cabina_admin_selectedChannel",
                  value: this.selectedChannel,
                }); 
        setTimeout(() => {
          //this.getLogs();
          this.refreshLogList();
        }, 1000);
      },
      firstLoad() { 
        this.selectedChannel = this.$refs.selectedChannel.value;
        console.log("firstLoad::DASHBOARD: ", this.selectedChannel);
        this.emitter.emit("selectedChannel", this.selectedChannel);
                Storage.set({
                  key: "cabina_admin_selectedChannel",
                  value: this.selectedChannel,
                }); 
        setTimeout(() => {
          //this.getLogs();
          this.refreshLogList();
        }, 1000);
      },
      handleDateChange() {
        console.log("handleDateChange::Obteniendo handleDateChange...");
        if (this.date_from > this.date_to) {
           this.date_validation = 'menor';
           this.date_to = this.date_from;
        } else {
          if (moment(this.date_to).diff(moment(this.date_from), 'days') > 31) {
            this.date_validation = 'mayor';
          }else{
            this.date_validation = 'ok';
          }
        }
      },
      handlelogChange(event) {
        setTimeout(() => {
          //this.getListLogs();
        }, 1000);
      },
      async refreshLogList() {
        this.isloading = true;
        console.log("handleChannelChange::DASHBOARD: ", this.dashboard);
  
          const con = [];
          con.push(["Base", "Base"]);
          const ContentVariants = this.dashboard.radio.ContentVariants;
          if (ContentVariants) {
            ContentVariants.forEach(cvar => {
              const con_aux = [`${cvar.id}`,`${cvar.name}`];
              con.push(con_aux);
            });
    
            this.log_list = con;
            
                  // ordenar numerica y alfabeticamente excepto base, siempre debe estar al tope 
                  this.log_list.sort((a, b) => {
                    if (a[0] === "Base") {
                      return -1;
                    }
                    if (b[0] === "Base") {
                      return 1;
                    }
                    return a[1].localeCompare(b[1]);
                  });
  
            this.selectedlog = "Base";
            console.log("Combined Array: ", con);
            this.isloading = false;
          }else{
            this.firstLoad();
          }
      },
      async cargarReportes() { 
        this.alertaSinArvhivos = false;
      if (this.date_from > this.date_to) {
        console.log("cargarReportes::Fecha de inicio mayor a fecha final");
        const alert = await alertController
          .create({
            header: "Aviso",
            message: "La fecha de inicio no puede ser mayor a la fecha final.",
            buttons: [
              {
                text: 'Aceptar',
                handler: () => {
                  //console.log('Aceptar pulsado');
                }
              },
            ]
          }); 
          return alert.present();
      } else { 
      if (moment(this.date_to).diff(moment(this.date_from), 'days') > 31) {
        console.log("cargarReportes::Rango de fechas mayor a 31 días");
        const alert = await alertController
          .create({
            header: "Aviso",
            message: "El rango de fechas no puede ser mayor a 31 días.",
            buttons: [
              {
                text: 'Aceptar',
                handler: () => {
                  //console.log('Aceptar pulsado');
                }
              },
            ]
          }); 
          return alert.present();
      } else {
        this.isloading = true;
        this.mhora_s_table = [];
        this.logTitleObj = {};
        try {
          console.log("cargarReportes::Obteniendo cargarReportes...");
          var videoAux = '';
              if (this.dashboard.radio.video_enabled) {
                videoAux = '_v';
              }
          const stream = this.dashboard.radio.stream+videoAux;
            const from = moment(this.date_from).format('YYYY-MM-DD');
            const to = moment(this.date_to).format('YYYY-MM-DD');
          const log = this.selectedlog;

          const radioName = this.dashboard.radio.name;
          this.reportName = 'Reporte de Horas por Sucursal ' + radioName + ' del ' + from + ' al ' + to + ' |' + log + '|';
          this.reportFirstPage = radioName;
  
          const ContentVariants = this.dashboard.radio.ContentVariants;
          const MusicVariants = this.dashboard.radio.MusicVariants;
  
          const conmus = [];
          const con = [];
          const mus = [];
  
          if (log == "Todos" || log == "Base") {
            conmus.push([`${stream}`, `Base`]);
          }
  
          ContentVariants.forEach(cvar => {
            MusicVariants.forEach(mvar => {
              if (log == "Todos" || log == cvar.id) {
                const conmus_aux = [`${stream}__${cvar.id}==${mvar.id}`, `${cvar.name} - ${mvar.name}`];
                conmus.push(conmus_aux);
              }
            });
          });
  
          ContentVariants.forEach(cvar => {
            if (log == "Todos" || log == cvar.id) {
              const con_aux = [`${stream}__${cvar.id}`, `${cvar.name}`];
              con.push(con_aux);
            }
          });
  
          MusicVariants.forEach(mvar => {
            if (log == "Todos" || log == "Base") {
              const mus_aux = [`${stream}==${mvar.id}`, `${mvar.name}`];
              mus.push(mus_aux);
            }
          });
  
          const combinedArray = conmus.concat(con, mus);
          // ordenar combinedArray
          combinedArray.sort((a, b) => {
            if (a[1] === "Base") {
              return -1;
            }
            if (b[1] === "Base") {
              return 1;
            }
            return a[1].localeCompare(b[1]);
          });
          console.log("Combined Array: ", combinedArray);
        
          // Array para almacenar las promesas de las peticiones axios
          const promises = [];
  
          combinedArray.forEach(sLink => {
            const fromDate = new Date(from);
            const toDate = new Date(to);
     
            let sucName = sLink[1];
            let sucMusicCate = "";
            if (sLink[0].includes("==")) { 
              sucMusicCate = sLink[1].split(' - ').pop();
              sucName = sLink[1].split(' - ').shift();
            }
  
            if (sucName == sucMusicCate) {
              sucName = "Base";
            }
  
            let currentDate = new Date(fromDate);
  
            while (currentDate <= toDate) {
              const formattedDate = currentDate.toISOString().split('T')[0];
                  
              const url = `https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/play_logs/${sLink[0]}/${formattedDate}.txt`;
  
              const promise = axios.get(url)
                .then(response => {
                  //validate 404
                  if (response.status != 404) {
                    const textLogList = response.data.split('\n');
                    var horaAnterior = '00:00:00';
                    var horaActual = '00:00:00';
                    textLogList.forEach(log => {
                      if (log.length > 10) {
                        const logParts = log.split('[');
                        const logTime = logParts[0].trim().substring(0, 8);
                        horaAnterior = horaActual;
                        horaActual = logTime;
                        
                        var horaAnteriorParts = horaAnterior.split(':');
                        var horaActualParts = horaActual.split(':');
                        var horaAnteriorSeconds = (+horaAnteriorParts[0]) * 60 * 60 + (+horaAnteriorParts[1]) * 60 + (+horaAnteriorParts[2]);
                        var horaActualSeconds = (+horaActualParts[0]) * 60 * 60 + (+horaActualParts[1]) * 60 + (+horaActualParts[2]);
                        var seconds = horaActualSeconds - horaAnteriorSeconds;
                        
                        if (seconds > 600) {
                          seconds = 0;
                        }
    
                          if (this.logTitleObj.hasOwnProperty(sucName)) {
                            this.logTitleObj[sucName].count++;
                            this.logTitleObj[sucName].seconds += seconds;
                              const existingLog = this.logTitleObj[sucName].logs.find(log => log.date === formattedDate);
                              if (existingLog) {
                                existingLog.seconds += seconds;
                                existingLog.count++;
                              } else {
                              this.logTitleObj[sucName].logs.push({
                                date: formattedDate,
                                seconds: seconds,
                                count: 1,
                              });
                              }
                          } else {
                            this.logTitleObj[sucName] = {
                              count: 1,
                              seconds: seconds,
                              logs: [
                                {
                                  date: formattedDate,
                                  seconds: seconds,
                                  count: 1,
                                }
                              ]
                            };
                          }
                      }
                    });
                  }
                })
                .catch(error => {
                  //console.log("2 Error en función cargarReportes de cargarReportes: " + error.message);
                });
  
              promises.push(promise);
              currentDate.setDate(currentDate.getDate() + 1);
            }
          });
  
          await Promise.all(promises); // Espera a que todas las peticiones se completen
  
          // Transformar mhora_s_table
            this.mhora_s_table = Object.keys(this.logTitleObj)
            .map(key => ({
              title: key,
              count: this.logTitleObj[key].count,
              seconds: this.logTitleObj[key].seconds,
              logs: this.logTitleObj[key].logs.sort((a, b) => new Date(a.date) - new Date(b.date)) // Ordenar logs por fecha
            }))
            .sort((a, b) => b.count - a.count);
  
            console.log("mhora_s_table: ", this.mhora_s_table);
          
          //if this.mhora_s_table is empty, show alert in console
          if (this.mhora_s_table.length <= 0) {
            console.log("No existen registros para la fecha seleccionada.");
            this.alertaSinArvhivos = true;
          }
  
          this.filterLogs();
          this.isloading = false;
  
        } catch (error) {
          //console.log("1 Error en función cargarReportes de cargarReportes: " + error.message);
        }
      }}
      },
  
      async getLogs() {
        this.isloading = true;
        this.mhora_s_table = [];
        try {
          console.log("getLogs::Obteniendo getLogs...");
          //https://panel.vybroo.net/get_available_logs
          
          this.isloading = false;
        } catch (error) {
          console.log("Error en función getLogs de Conexiones: " + error.message);
        }
      },
      async presentAlertConfirm(headerAlert, messageAlert, buttonTextConfirm, buttonTextCancel, functionConfirm, functionCancel) {
        const alert = await alertController
          .create({
            header: headerAlert,
            message: messageAlert,
            buttons: [
              {
                text: buttonTextConfirm,
                handler: () => {
                  functionConfirm();
                }
              },
              {
                text: buttonTextCancel,
                handler: () => {
                  functionCancel();
                }
              }
            ]
          });
        return alert.present();
      },
      async signOut() {
        const alert = await alertController
          .create({
            header: "Aviso",
            message: "¿Estás seguro que deseas cerrar la sesión?",
            buttons: [
              {
                text: 'Si',
                handler: () => {
                  Storage.remove({ key: 'authToken' });
                  Storage.remove({ key: 'cabina_admin_user' });
                  Storage.remove({ key: 'cabina_admin_channel_list' });
                  Storage.remove({ key: 'cabina_admin_selectedChannel' });
                  this.$router.replace("/admin/LoginAdmin");
                  this.emitter.emit('cabina_admin_user', '',);
                  this.emitter.emit('cabina_admin_channel_list', '',);
                }
              },
              {
                text: 'Cancelar',
                handler: () => {
                  //console.log('Cancelar pulsado');
                }
              },
            ]
          });
        return alert.present();
  
      }, 
      async getStorageClientData(keyName) {
        try {
          const ret = await Storage.get({ key: keyName });
          this.clientData = this.isJson(ret.value);
        } catch (error) {
          //console.log("Error en función getStorageClientData de Conexiones: " + error.message);
        }
      }, 
      isJson(str) {
        try {
          const parsedStr = JSON.parse(str);
          return parsedStr;
        } catch (e) {
          return str;
        }
      },
      async getStorageAuthToken() {
        try {
          //console.log("Obteniendo token del storage...")
          const ret = await Storage.get({ key: 'authToken' });
          if (ret.value) {
            const tokenObj = JSON.parse(ret.value);
            this.token = tokenObj.value;
            //console.log("Token obtenido: " + this.token);
            // console.log("Token obtenido:" + this.token)
            return this.validateToken(this.token);
          } else {
            //console.log("Token no encontrado");
            this.presentAlertTokenInvalid();
            return false;
          }
        } catch (error) {
          //console.log("Error en función getStorageAuthToken de Conexiones: " + error.message);
        }
      },
      async presentAlertTokenInvalid() {
        const alert = await alertController
          .create({
            header: "Aviso",
            message: "Tu sesión ha expirado, por favor inicia sesión nuevamente",
            buttons: [
              {
                text: 'Aceptar',
                handler: () => {
                  this.$router.replace("/admin/LoginAdmin");
                }
              },
            ]
          });
        return alert.present();
      },
      async validateToken(token) {
        try {
          //console.log("revisando validez de token: " + token + " ...");
          if (token == null || token == undefined || token == "") {
            this.presentAlertTokenInvalid();
            return false;
          }
          if (token != null && token != undefined && token != "") {
            let url = "api/user_auth";
            if (Capacitor.getPlatform() == 'ios') url = "https://panel.vybroo.net/user_auth";
            let response = await axios.post(url, { token: token })
              .then(r => {
                //console.log("r.data:");
                //console.log(r.data);
                return r;
              }).catch(error => {
                //console.log("3Error en función validateToken de Conexiones: " + error.message);
                return error;
              });
            //console.log("response.data:");
            //console.log(response.data);
            //console.log("response.data.success: " + response.data.success);
            //console.log("response.data.error: " + response.data.error);
            if (response.data.error === true) {
              //console.log("Error al validar token:");
              //console.log(response.data);
              this.presentAlertTokenInvalid();
              return false;
            }
            if (response.data.success === true) {
              //console.log("Token válido");
              return true;
            } else {
              //console.log("Token inválido");
              //console.log("2response.data.success: " + response.data.success);
              //console.log("2response.data.error: " + response.data.error);
              this.presentAlertTokenInvalid();
              return false;
            }
          }
        } catch (error) {
          //console.log("4Error en función validateToken de Conexiones: " + error.message);
          return false;
        }
      },
      async getFullClientData() {
        try {
          const ret = await Storage.get({ key: "clientData" });
          if (ret.value) {
            this.clientData = JSON.parse(ret.value);
            //console.log("Recuperado de storage, key: clientData - " + this.clientData);
  
            if (this.clientData.sonara) {
              this.emitter.emit("sonara", true);
            } else {
              this.emitter.emit("sonara", false);
            }
          } else {
            //console.log("No se pudo recuperar de storage, key: clientData - " + this.clientData);
          }
        } catch (e) {
          this.presentAlertConfirm("Ha ocurrido un error en la función getFullClientData", e);
        }
      },
      async getChannelList(){
        try {
          const ret = await Storage.get({ key: "cabina_admin_channel_list" });
          if (ret.value) { 
              this.channel_list = JSON.parse(ret.value).slice();
              this.emitter.emit("channel_list", this.channel_list);
              this.channel_name_list = [];
              if (this.channel_list){
                this.channel_list.forEach(element => {
                  this.channel_name_list.push({ id: element.id, name: element.name });
                });
                this.emitter.emit("channel_name_list", this.channel_name_list);
                //console.log("Recuperado de storage, key: cabina_admin_channel_list - " , this.channel_list);
                //console.log("Recuperado de storage, key: channel_name_list - " , this.channel_name_list);
                if (this.selectedChannel == [] || this.selectedChannel == null || this.selectedChannel == undefined) {
                  const selectedChannelAux = await Storage.get({ key: "cabina_admin_selectedChannel" });
                  //console.log("selectedChannelAux: " , selectedChannelAux);
                  if (selectedChannelAux.value) {
                    this.selectedChannel = selectedChannelAux.value;
                    this.emitter.emit("selectedChannel", this.selectedChannel);
                    Storage.set({
                      key: "cabina_admin_selectedChannel",
                      value: this.selectedChannel,
                    });
                  } else {
                    this.selectedChannel = this.channel_list[0].id;
                    this.loadChannelDashboard(this.selectedChannel);
                    this.emitter.emit("selectedChannel", this.selectedChannel);
                    Storage.set({
                      key: "cabina_admin_selectedChannel",
                      value: this.selectedChannel,
                    });
                  }
                  //this.selectedChannel = this.channel_list[0].id;
                  //this.loadChannelDashboard(this.selectedChannel);
                  //this.emitter.emit("selectedChannel", this.selectedChannel);
                }
              }
          } else {
            console.log("No se pudo recuperar de storage, key: cabina_admin_channel_list - " , this.channel_list);
          }
        } catch (error) {
          console.log("Error en función cabina_admin_channel_list de Dashboard: " , error.message);
          return false;
        }
      }
    },
    mounted: function () { 
  setTimeout(() => {
  
      try {
        if (Capacitor.getPlatform() == 'ios') {
          this.urlApi = "https://panel.vybroo.net/";
        } else {
          this.urlApi = "/api/";
        } 
  
        //get auth token de storage y asignarlo a la variable token
        this.getStorageAuthToken();
        this.getStorageClientData('clientData');
        this.getChannelList();
  
        this.emitter.on("selectedChannel", (channel) => {
          //console.log("conexiones::::emitedON:selectedChannel: " , channel);
          this.selectedChannel = channel;
          //this.getLogs(); 
        });
        this.emitter.on("channel_list", (channel_list) => {
          //console.log("conexiones::::emitedON:channel_list: " , channel_list);
          this.channel_list = channel_list;
        });
        this.emitter.on("channel_name_list", (channel_name_list) => {
          //console.log("conexiones::::emitedON:channel_name_list: " , channel_name_list);
          this.channel_name_list = channel_name_list;
        });
        this.emitter.on("dashboard", (dashboard) => {
          //console.log("conexiones::::emitedON:dashboard: " , dashboard);
          this.dashboard = dashboard; 
        });
   
      } catch (error) {
        console.log("Error en función mounted de Conexiones: " + error.message);
      }
  
       
  }, 1000);
  setTimeout(() => {
    this.firstLoad();
  }, 2000);
    },
    
  
  })
  </script>
  <style lang="css"> 
  .bottom-space-on-float-audio {
    height: 100px;
  }
  .pp_detallesButton {
    cursor: pointer;
    text-align: center;
    width: 22px;
    display: block;
    margin: auto;
  }
  .pp_escucharButton {
    cursor: pointer;
    text-align: center;
    width: 22px;
    display: block;
    margin: auto;
  }
  .pp_escucharButton .vvplay {
    display: block;
    margin: auto;
  }
  .pp_escucharButton .vvpausa {
    display: none;
    animation: pulse .5s infinite;
    margin: auto;
  } 
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .pp_escucharButton:hover .vvplay {
    scale: 1.2;
  }
  .pp_escucharButton:hover .vvpausa {
    scale: 1.2;
  }
  .pp_escucharButton.activo .vvplay {
    display: none;
  }
  .pp_escucharButton.activo .vvpausa {
    display: block;
  }
  .pp_detallesButton ion-icon {
    width: 22px;
    height: 22px;
  }
  .pp_detallesButton ion-icon:hover {
    scale: 1.2;
  }
  .vMusicFloat{
    position: fixed;
      bottom: 20px;
      left: 50%;
      background-color: #000000;
      color: #ffffff;
      z-index: 1000;
      border: solid 1px #ccc;
      padding: 0px 15px;
      width: 420px;
      transform: translateX(-50%);
      border-radius: 7px;
      box-shadow: 0px 0px 20px RGBA(0,0,0,.5);
  }
  .vMusicFloat #vMusicPlayer{
    width: 100%;
  }
  .vMusicFloat .vMusicDiv {
     padding: 6px 2px 10px 2px;
  }
  .vMusicFloat .vMusicDiv p {
     width: calc(100% - 20px);
      text-align: left;
      font-size: 1.1em;
      font-weight: 600;
      margin: 0px;
      padding: 10px 0px;  
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
  .vMusicFloat .vMusicClose{
    position: absolute;
    right: 10px;
    top: 10px;
    color: #ffffff;
    cursor: pointer;
  
  }
  .vMusicFloat .vMusicCloseIcon{
    width: 20px;
    height: 20px;
  }
  .vMusicFloat .vMusicClose:hover{
    scale: 1.2; 
  }
   
  
  /* PRETTY BUTTON ------------*/
  .cargar_reportes_button {
    width: 130px;
    height: 40px;
    color: #fff;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
      7px 7px 20px 0px rgba(0, 0, 0, .1),
      4px 4px 5px 0px rgba(0, 0, 0, .1);
    outline: none;
    margin: 20px auto;
    display: block;
  }
  .sc-btn-3 {
    color: white;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    font-weight: bold;
    font-size: 16px;
    background: linear-gradient(0deg, #2dd36f 0%, #2dd36f 100%);
    width: 180px;
    height: 40px;
    line-height: 42px;
    padding: 0;
    border: none;
  
  }
  .sc-btn-3 span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }
  .sc-btn-3:before,
  .sc-btn-3:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    background: #2dd36f;
    transition: all 0.3s ease;
  }
  .sc-btn-3:before {
    height: 0%;
    width: 2px;
  }
  .sc-btn-3:after {
    width: 0%;
    height: 2px;
  }
  .sc-btn-3:hover {
    background: transparent;
    color: #2dd36f;
    font-weight: bold;
    box-shadow: none;
  }
  .sc-btn-3:hover:before {
    height: 100%;
  }
  .sc-btn-3:hover:after {
    width: 100%;
  }
  .sc-btn-3 span:hover {
    color: #2dd36f;
  }
  .sc-btn-3 span:before,
  .sc-btn-3 span:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    background: #2dd36f;
    transition: all 0.3s ease;
  }
  .sc-btn-3 span:before {
    width: 2px;
    height: 0%;
  }
  .sc-btn-3 span:after {
    width: 0%;
    height: 2px;
  }
  .sc-btn-3 span:hover:before {
    height: 100%;
  }
  .sc-btn-3 span:hover:after {
    width: 100%;
  }
  /* PRETTY BUTTON ------------*/
  .spot_log_select_e {
      width: 100% ;
      margin: 0 0 0 16px;
      padding: 10px;
      border-radius: 5px;
      border: solid 1px #232323;
      background-color: #000000;
      color: #ffffff;
      font-size: 1em;
      font-weight: 400;
      margin-bottom: 10px;
  }
  .spot_date_select { 
      margin: 0 0 0 8px;
      padding: 10px;
      border-radius: 5px;
      border: solid 1px #232323;
      background-color: #000000;
      color: #ffffff;
      font-size: 1em;
      font-weight: 400;
      margin-bottom: 10px;
  }
  .spot_date_select_from,
  .spot_date_select_to {
      width: 50% !important; 
  }
  .mlog_date_div_es .alertaFechas {
    color: #ff4343;
      font-size: 12px;
      position: absolute;
      bottom: -43px;
      background: black;
      border-radius: 6px;
      padding: 4px 7px;
      border: solid red 1px;
      width: calc(100% - 20px);
      left: calc(50% + 4px);
      transform: translateX(-50%);
      display: none;
      z-index: 1000;
  }
  .mlog_date_div_es:hover .alertaFechas {
    display: block;
  }
  .mlog_date_div_es .alertaFechas:before{
    content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid red;
      top: -6px;
      left: calc(50% - 6px);
  
  }
  .mlog_date_div_es{
    width: 280px !important;
    max-width: 280px !important;
    min-width: 280px !important;
    display: flex;
  }
  .spot_log_select_div{
    width: calc(100% - 300px) !important;
    max-width: calc(100% - 300px) !important;
    min-width: calc(100% - 300px) !important;
  }
  
  .log_details_g{ 
    padding: 0px !important;
  }
  
  .mhora_s_table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    font-size: 1em;
    font-weight: 400;
    color: #ffffff;
    background-color: #000000;
    border-radius: 5px;
    border: solid 1px #232323;
  }
  .mhora_s_table th {
    padding: 5px;
    border-bottom: solid 1px #232323;
    text-align: left;
  }
  .mhora_s_table td {
    padding: 5px;
    border-bottom: solid 1px #232323;
    text-align: left;
  }
  .mhora_s_table tr:hover{
    background-color: #232323;
  } 
  
  .mhora_s_table th {
    position: sticky;
    top: 0;
    background-color: #000000;
    z-index: 1;
  } 
  .mhora_s_table th:nth-child(1) {
    width: 20px;
  } 
  .mhora_s_table .acciones {
    width: 24px;
    text-align: center
  }
  .mhora_s_table td.accioness {
    width: 50px;
  } 
  .mhora_s_table .count { 
    cursor: help;
  }
  .mhora_s_table .count:hover {
    background: #363636;
  }
  .mhora_s_table .count:hover:before {
      content: 'Numero de Audios totales';
      position: absolute;
      background-color: #363636;
      color: #fff;
      padding: 10px 15px;
      border-radius: 5px;
      font-size: 12px;
      left: 0;
      top: 25px;
      border: solid 1px #232323;
      z-index: 2000;
  }
  .mhora_s_table .accioness .pp_escucharButton,
  .mhora_s_table .accioness .pp_detallesButton{
     position: relative;
  }
  
  .mhora_s_table .accioness .pp_escucharButton:hover ion-icon,
  .mhora_s_table .accioness .pp_detallesButton:hover ion-icon{ 
    filter: brightness(0.8);
  }
  .mhora_s_table .accioness .pp_escucharButton:hover:before{
      content: 'Escuchar';
      position: absolute;
      background-color: #363636;
      color: #fff;
      padding: 10px 15px;
      border-radius: 5px;
      font-size: 12px;
      left: 0;
      top: 25px;
      border: solid 1px #232323;
      z-index: 2000;
  }
  .mhora_s_table .accioness .pp_escucharButton.activo:hover:before{
      content: 'Escuchando';
  }
  .mhora_s_table .accioness .pp_detallesButton:hover:before{  
      content: 'Detalles';
      position: absolute;
      background-color: #363636;
      color: #fff;
      padding: 10px 15px;
      border-radius: 5px;
      font-size: 12px;
      left: 0;
      top: 25px;
      border: solid 1px #232323;
      z-index: 2000;
  }
  @media (max-width: 767px) {
    .mhora_s_table {
      width: 100%;
          max-width: 100%;
          height: calc(100vh - 400px);
          overflow: auto !important;
    }
    .mhora_s_table th {
      position: sticky;
      top: 0;
      background-color: #000000;
      z-index: 1;
    }
 
  }
  
  
  
  .mlog_date_div_es > .spot_date_select_from.menor,
  .mlog_date_div_es > .spot_date_select_to.menor {
    border: solid 1px #ff4f4f;
  }
  .mlog_date_div_es > .spot_date_select_from.mayor,
  .mlog_date_div_es > .spot_date_select_to.mayor {
    border: solid 1px #ff4f4f;
  }
  .mlog_date_div_es > .spot_date_select_from.ok,
  .mlog_date_div_es > .spot_date_select_to.ok {
    border: solid 1px #232323;
  }
   
  
  </style>